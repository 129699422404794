// ########################################
// THIS FILE IS UNIQUE PER ENVIRONMENT
// ########################################

//some functions only work for prod, so it needs it's own variable.  It's often compared to ENVIRONMENT, below
export const PRODUCTION_ENVIRONMENT = "production"; //was 'fintech', 

//some functions exclude localhost.  It's often compared to ENVIRONMENT, below
export const LOCALHOST_ENVIRONMENT = "localhost";

//Replacing all the environment specific variables, as this file will change per env
// export const ENVIRONMENT = LOCALHOST_ENVIRONMENT; // set to localhost, dev, testing, production in the appropriate env constants file
export const ENVIRONMENT = "dev"; 
// export const ENVIRONMENT = "testing"; 
// export const ENVIRONMENT = PRODUCTION_ENVIRONMENT; 


//#### old variables
//export const LOCALHOST = "localhost";
// export const DEV = "dev";
//export const STAGING = "testing";  //WAS dev or testing
//export const PROD = "production";

// export const ENVIRONMENT_URL = "http://localhost:3000";
export const ENVIRONMENT_URL = "https://dev.fintechmeetup.com/";
// export const ENVIRONMENT_URL = "https://testing.fintechmeetup.com/";
// export const ENVIRONMENT_URL = "https://fintechmeetup.com/";

//#### old variables
// export const PROD_URL = "https://fintechmeetup.com";
//export const STAGING_URL = "https://dev.fintechmeetup.com"; //WAS testing.fintech... or dev.fintech...

//Database function, reads appropriate Google Sheet
export const ENVIRONMENT_COPIES_URL = "https://us-central1-fintech-meetup-dev.cloudfunctions.net/fintech-website-dev"; // for localhost or dev
// export const ENVIRONMENT_COPIES_URL = "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-non-prod"; // staging
// export const ENVIRONMENT_COPIES_URL = "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-prod"; // production


//#### old variables  
// export const LOCAL_COPIES_URL =
//   "https://us-central1-fintech-meetup-dev.cloudfunctions.net/fintech-website-dev"; // for localhost or dev
//          or "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-non-prod" for the staging site
// export const PROD_COPIES_URL =
//   "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-prod";


// for all pre-prod sites
export const ENVIRONMENT_HUBSPOT_FORM_IDS = {
  SPONSOR: "d46bd97d-b628-437b-8ca8-b186d948d645",
  GET_TICKET: "384342d9-bfcb-4769-9073-e87b7ed268b7",
  APPLY_TO_SPEAK: "88b1619b-db00-4c7b-a044-2c22e5e9d7bb",
  CO_LOCATED_EVENTS: "d4e132af-4380-40d7-bbd7-5ac62c2f72a2"
};

// for prod site:
// export const ENVIRONMENT_HUBSPOT_FORM_IDS = {
//   SPONSOR: "89d32b61-aed4-4eb1-9dd2-bfac90dc938d",
//   GET_TICKET: "03626cec-d8a8-4f90-b7bc-64c96ee38c04",
//   APPLY_TO_SPEAK: "ee9cbbd1-4e67-4f0c-af37-d2bebc7bf2d0",
//   CO_LOCATED_EVENTS: "343472e6-3a5f-4f01-ab48-d4536dedacbc"
// };

export const ENVIRONMENT_ASSETS_URL = "https://c.fintechmeetup.com"; // use c for localhost and Dev
// export const ENVIRONMENT_ASSETS_URL = "https://b.fintechmeetup.com"; // for Testing
// export const ENVIRONMENT_ASSETS_URL = "https://a.fintechmeetup.com"; // for Production



export const SPEAKERS_DATA_API_URL = "https://us-central1-fintech-meetup-dev.cloudfunctions.net/fintech-speaker-dev"; //for localhost, Dev, and Testing
// export const SPEAKERS_DATA_API_URL = "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-speaker-prod"; // for prod

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// The following variables seem to be the same for all environments
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const FREE = "FREE";
export const SCROLL_OFFSET = -130;
export const SECTION_OFFSET = 132;
export const SCROLL_DURATION = 500;
export const SECTION_OFFSET_ABOUT = 146;
export const PRIVACY_SCROLL_OFFSET = -170;
export const TEXT_AREA_MAX_LENGTH = "2000";
export const HUBSPOT_PORTAL_ID = "9197169";
export const LOCAL_API_URL = "http://localhost:8000";
export const LOCAL_REG_URL = "http://127.0.0.1:3000";
export const SPONSOR_FORM = "89d32b61-aed4-4eb1-9dd2-bfac90dc938d";
export const WAITLIST_FORM_ID = "e60fc803-bd9e-486c-a870-d42bff452fe9";
export const SPEAKER_FORM = "f98650c2-a3e0-4b2c-9934-1b61afcb9db9";
export const CO_LOCATED_EVENTS_FORM = "36e0d7ee-27b0-4c01-967e-ff5a5835b0c5";
export const CO_LOCATED_SPEAKERS_FORM = "2cdc9c5d-bb7a-477d-bccd-7e364e882ba8";
export const APPLY_TO_SPEAK_FORM = "ac4ac6a0-b3b2-4dcf-b38b-6c4b9adc6cec";
export const DEFAULT_FAVICON_URL = `${process.env.PUBLIC_URL}/favicon.ico`;

export const MIN_COUPON_CODE_LENGTH = 5;
export const MAX_COUPON_CODE_LENGTH = 50;
export const TRUE = "true";
export const HOSTED_TICKETS = ["retailerFree", "bankFree"];

export const FORM_ID = "fId";
export const COUPON_CODE = "cId";
export const SPONSORS_ORG_ID = "spId";
export const REGISTRATION_ROUTE_INITIATE_REG = "/form/initiate";
export const MARQUEE_COUNT = 10;
export const MAX_LENGTH_140 = 140;
export const MAX_LENGTH_500 = 500;
